<template>
  <div>
    
    <v-card class="pa-2">
        <h1>about the user</h1>
        <table>
            <tr v-for="(item, index) in $store.state.module_login.info_login" :key="item.id">
                <td><i>{{index}}</i></td>
                <td class="pl-2"><b>{{item}}</b></td>
            </tr>
        </table>
    </v-card>
    <!-- store.state.module_login.info_login = {{$store.state.module_login.info_login}} -->

  </div>
</template>

<script>

export default {
	//name: "eduBase",


	data: () => ({
        item: null,
	}),
}

</script>
    

